export function filterImageLink(link, smallEnd = this.$common.K_QINIU_IMAGE_SMALL_END_100_PX) {
    if (!link) return link;
    const validExtensions = ['.jpg', '.jpeg', '.png'];
    
    // 检查链接的文件扩展名是否合法
    const extension = link.substring(link.lastIndexOf('.'));
    if (!validExtensions.includes(extension.toLowerCase())) {
      return link;
    }
    return link + smallEnd;
  }
  
export const isEmpty = (params) => {
  if ([undefined, null, NaN, ""].includes(params)) return true;
  const type = Object.prototype.toString.call(params);
  if (["[object Array]", "[object Object]"].includes(type)) {
    return Object.keys(params).length === 0;
  }
  return false;
};

// 格式化日期 实例 common.formatDate(date, 'YYYY-MM-DD hh:mm')
export function formatDate(date, fmt = "YYYY-MM-DD hh:mm:ss") {
  if (!date) return "";
  var dateTemp = "";
  if (date > 0) {
    if (date.toString().length === 10) {
      date *= 1000;
    }
    dateTemp = new Date(Number(date));
  } else {
    dateTemp = new Date(date);
  }
  if (dateTemp.toString() === "Invalid Date") {
    dateTemp = new Date(date.toString().replace(/-/g, "/"));
    if (dateTemp.toString() === "Invalid Date") {
      return date;
    }
    return date;
  } else {
    date = dateTemp;
  }
  var o = {
    "M+": date.getMonth() + 1, // 月份
    "D+": date.getDate(), // 日
    "h+": date.getHours(), // 小时
    "m+": date.getMinutes(), // 分
    "s+": date.getSeconds(), // 秒
    "q+": Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds(), // 毫秒
  };
  if (/(Y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  for (var k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  }
  return fmt;
}

export function isNumber(num) {
  return typeof num === "number" && !isNaN(num);
}